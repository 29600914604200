import { useEffect, useRef, useState } from "react";
import "./App.css";

// todo-> refactor and simplify this
const typableCharacters = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z", // Lowercase letters
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z", // Uppercase letters
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9", // Numbers
  "!",
  "@",
  "#",
  "$",
  "%",
  "^",
  "&",
  "*",
  "(",
  ")",
  "-",
  "_",
  "=",
  "+",
  "[",
  "]",
  "{",
  "}",
  "\\",
  "|",
  ";",
  ":",
  "'",
  '"',
  ",",
  ".",
  "/",
  "<",
  ">",
  "?",
  "`",
  "~", // Special characters
  " ", // Space
];

function keyProcessor(event, notes, focusedNotePos) {
  console.log("focusedNotePos", focusedNotePos);
  let notePosition = focusedNotePos;
  if (event.key === "Enter") {
    notes.push("");
    notePosition = notePosition + 1;
    return { newNotes: notes, notePosition };
  }
  if (event.key === "ArrowUp") {
    if (notePosition > 0) {
      notePosition = notePosition - 1;
    }
    return {
      newNotes: notes,
      notePosition,
    };
  }
  if (event.key === "ArrowDown") {
    if (notePosition < notes.length - 1) {
      notePosition = notePosition + 1;
    }
    return {
      newNotes: notes,
      notePosition,
    };
  }

  let notesAtNotePosition = notes[focusedNotePos] ?? "";
  if (event.key === "Backspace") {
    // already if the line is empty and we do back space, we should remove the line
    if (notesAtNotePosition.length === 0) {
      notes.splice(notePosition, 1);
      notePosition = notePosition - 1;
      return { newNotes: notes, notePosition };
    }
    notesAtNotePosition = notesAtNotePosition.slice(0, -1);
  } else if (typableCharacters.includes(event.key)) {
    notesAtNotePosition = notesAtNotePosition + event.key;
  }

  notes[notePosition] = notesAtNotePosition;
  return { newNotes: notes, notePosition };
}

function downloadFile(notes) {
  const element = document.createElement("a");
  const file = new Blob([notes.join("\n")], { type: "text/plain" });
  element.href = URL.createObjectURL(file);
  element.download = "notes.txt";
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  element.remove();
}

function calculateMinuteCutOffNumber(seconds) {
  const minutes = seconds / 60;
  if (minutes < 1) {
    return `You have been writing for < 1 minute`;
  }

  const roundedMinute = Math.floor(minutes);

  let statusTExt = `You have been writing for ${Math.floor(minutes)} minute`;
  if (roundedMinute > 1) {
    statusTExt = statusTExt + "s";
  }
  return statusTExt;
}

function App() {
  const [notes, setNotes] = useState([""]);
  const [focusedNotePos, setFocusedNotePos] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const bodyRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setSeconds(seconds + 1);
    }, 1000);
  }, [seconds]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log(event.key);
      const { newNotes, notePosition } = keyProcessor(
        event,
        notes,
        focusedNotePos
      );
      // console.log({ newNotes, notePosition });
      setNotes([...newNotes]);
      console.log("setting focused note pos", notePosition);
      setFocusedNotePos(notePosition<0?0:notePosition);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [focusedNotePos, notes]);

  const notesPriorToFocusedNote = notes.slice(0, focusedNotePos);
  const notesAfterFocusedNote = notes.slice(focusedNotePos + 1);
  const focusedNote = notes[focusedNotePos];
  const timerText = calculateMinuteCutOffNumber(seconds);
  return (
    <div className="App" contentEditable={false}>
      {focusedNotePos}
      <br />
      <div ref={bodyRef}>
        {notesPriorToFocusedNote.map((note, index) => (
          <p key={index} onClick={() => setFocusedNotePos(index)}>
            [{index}]{note}
          </p>
        ))}
        <input value={focusedNote}></input>
        {notesAfterFocusedNote.map((note, index) => (
          <p
            key={index}
            onClick={() => setFocusedNotePos(index + focusedNotePos + 1)}
          >
            [{index}]{note}
          </p>
        ))}
      </div>
      <br />

      <p>{timerText}</p>
      <button onClick={() => downloadFile(notes)}>Finish Writing</button>
    </div>
  );
}

export default App;
